<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card size="small">
        <template slot="title">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <h5>Cek Posisi Keuangan</h5>
              <a-checkbox v-model="tidaksama" @change="onChange">
                Hanya tampilkan yang tidak sama
              </a-checkbox>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <a-select
                v-model="filterKodeKantor"
                show-search
                class="float-right"
                placeholder="Pilih Kode Cabang"
                option-filter-prop="children"
                style="width: 100px"
                :filter-option="filterOption"
                @change="onSearch"
              >
                <a-select-option value="all">
                  All
                </a-select-option>
                <a-select-option v-for="(data, index) in dataCabangList" :key="index" :value="data.kode">
                  {{ data.kode }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </template>
        <div class="row" style="padding-left: 10px; padding-right: 10px;">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h5>Cek Posisi Keuangan</h5>
            <!-- <ag-grid-vue
                style="position:relative;height: 100vh;"
                :class="
                  theme === 'dark'
                    ? 'ag-theme-balham-dark mt-2'
                    : 'ag-theme-balham mt-2'
                "
                :skipHeaderOnAutoSize="true"
                :gridOptions="gridOptions"
                :columnDefs="columnDefs"
                :pagination="false"
                :cacheQuickFilter="true"
                :quickFilterText="tablequickfilter"
                :accentedSort="false"
                :sortingOrder="['asc', 'desc']"
                :rowData="rowDataKeuangan"
                @grid-ready="onGridReady"
                :singleClickEdit="false"
                @first-data-rendered="onFirstDataRendered"
                @selection-changed="onSelectionChanged"
                :rowClassRules="rowClassRules"
              >
              </ag-grid-vue> -->
            <a-table
              :bordered="false"
              :columns="columns"
              :data-source="rowDataKeuangan"
              style="margin-left: -13px; margin-right: -13px;"
              size="small"
              :loading="loading"
              :pagination="{
                hideOnSinglePage: true,
                defaultPageSize: 100,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ['100', '200', '300'],
              }"
            >
              <div
                slot="filterDropdown"
                slot-scope="{
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                  column,
                }"
                style="padding: 8px;"
              >
                <a-input
                  v-ant-ref="(c) => (searchInput = c)"
                  :placeholder="`Search ${column.dataIndex}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block;"
                  @change="
                    (e) =>
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                  "
                  @pressEnter="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                />
                <a-button
                  type="primary"
                  icon="search"
                  size="small"
                  style="width: 90px; margin-right: 8px;"
                  @click="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                >
                  Search
                </a-button>
                <a-button
                  size="small"
                  style="width: 90px;"
                  @click="() => handleReset(clearFilters)"
                >
                  Reset
                </a-button>
              </div>
              <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
              <template
                slot="customRender"
                slot-scope="text, record, index, column"
              >
                <span v-if="searchText && searchedColumn === column.dataIndex">
                  <template
                    v-for="(fragment, i) in text
                      .toString()
                      .split(
                        new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                      )"
                  >
                    <mark
                      v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                      :key="i"
                      class="highlight"
                      >{{ fragment }}</mark
                    >
                    <template v-else>{{ fragment }}</template>
                  </template>
                </span>
                <template v-else>
                  <span> {{ text }}</span>
                </template>
              </template>
              <span slot="keterangan" slot-scope="text" v-html="getHtml(text)">
                <!-- {{ text }} -->
              </span>
              <span slot="keterangan2" slot-scope="text" v-html="getHtml(text)">
                <!-- {{ text }} -->
              </span>
              <span slot="jumlah" slot-scope="text">
                {{ formatNumber(text) }}
              </span>
              <span slot="jumlah2" slot-scope="text">
                {{ formatNumber(text) }}
              </span>
              <span slot="selisih" slot-scope="text">
                {{ formatNumber(text) }}
              </span>
              <a-tag
                slot="status"
                slot-scope="text"
                :color="text === 'Sama' ? 'blue' : 'red'"
              >
                {{ text }}
              </a-tag>
            </a-table>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-5">
            <h5>Cek Laba Rugi</h5>
            <!-- <ag-grid-vue
                style="position:relative;height: 100vh;"
                :class="
                  theme === 'dark'
                    ? 'ag-theme-balham-dark mt-2'
                    : 'ag-theme-balham mt-2'
                "
                :skipHeaderOnAutoSize="true"
                :gridOptions="gridOptions"
                :columnDefs="columnDefs"
                :pagination="false"
                :cacheQuickFilter="true"
                :quickFilterText="tablequickfilter"
                :accentedSort="false"
                :sortingOrder="['asc', 'desc']"
                :rowData="rowDataKeuangan"
                @grid-ready="onGridReady"
                :singleClickEdit="false"
                @first-data-rendered="onFirstDataRendered"
                @selection-changed="onSelectionChanged"
                :rowClassRules="rowClassRules"
              >
              </ag-grid-vue> -->
            <a-table
              :bordered="false"
              :columns="columns"
              :data-source="rowDataLabaRugi"
              size="small"
              style="margin-left: -13px; margin-right: -13px;"
              :loading="loading"
              :pagination="{
                hideOnSinglePage: true,
                defaultPageSize: 100,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ['100', '200', '300'],
              }"
            >
              <div
                slot="filterDropdown"
                slot-scope="{
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                  column,
                }"
                style="padding: 8px;"
              >
                <a-input
                  v-ant-ref="(c) => (searchInput = c)"
                  :placeholder="`Search ${column.dataIndex}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block;"
                  @change="
                    (e) =>
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                  "
                  @pressEnter="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                />
                <a-button
                  type="primary"
                  icon="search"
                  size="small"
                  style="width: 90px; margin-right: 8px;"
                  @click="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                >
                  Search
                </a-button>
                <a-button
                  size="small"
                  style="width: 90px;"
                  @click="() => handleReset(clearFilters)"
                >
                  Reset
                </a-button>
              </div>
              <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
              <template
                slot="customRender"
                slot-scope="text, record, index, column"
              >
                <span v-if="searchText && searchedColumn === column.dataIndex">
                  <template
                    v-for="(fragment, i) in text
                      .toString()
                      .split(
                        new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                      )"
                  >
                    <mark
                      v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                      :key="i"
                      class="highlight"
                      >{{ fragment }}</mark
                    >
                    <template v-else>{{ fragment }}</template>
                  </template>
                </span>
                <template v-else>
                  <span> {{ text }}</span>
                </template>
              </template>
              <span slot="keterangan" slot-scope="text" v-html="getHtml(text)">
                <!-- {{ text }} -->
              </span>
              <span slot="keterangan2" slot-scope="text" v-html="getHtml(text)">
                <!-- {{ text }} -->
              </span>
              <span slot="jumlah" slot-scope="text">
                {{ formatNumber(text) }}
              </span>
              <span slot="jumlah2" slot-scope="text">
                {{ formatNumber(text) }}
              </span>
              <span slot="selisih" slot-scope="text">
                {{ formatNumber(text) }}
              </span>
              <a-tag
                slot="status"
                slot-scope="text"
                :color="text === 'Sama' ? 'blue' : 'red'"
              >
                {{ text }}
              </a-tag>
            </a-table>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  created() {
    this.gridOptions = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    this.filterKodeKantor = localStorage.getItem('defaultsandikc') || ''
    this.dataCabangList = this.user.cabangList
    this.onSearch()
    this.getCompare()
  },
  data() {
    return {
      loading: false,
      theme: this.$store.state.settings.theme,
      dataCabangList: [],
      tablequickfilter: '',
      gridOptions: null,
      gridApi: null,
      tidaksama: false,
      columns: [
        {
          title: 'Keterangan',
          dataIndex: 'keterangan',
          scopedSlots: {
            customRender: 'keterangan',
          },
        },
        {
          title: 'Jumlah',
          dataIndex: 'jumlah',
          scopedSlots: {
            customRender: 'jumlah',
          },
        },
        {
          title: 'Jumlah',
          dataIndex: 'jumlah2',
          scopedSlots: {
            customRender: 'jumlah2',
          },
        },
        {
          title: 'Keterangan',
          dataIndex: 'keterangan2',
          scopedSlots: {
            customRender: 'keterangan2',
          },
        },
        {
          title: 'Selisih',
          dataIndex: 'selisih',
          scopedSlots: {
            customRender: 'selisih',
          },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          scopedSlots: {
            customRender: 'status',
          },
        },
      ],
      columnDefs: [
        {
          headerName: 'Keterangan',
          field: 'keterangan',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          // suppressSizeToFit: true,
          scopedSlots: {
            customRender: 'keterangan',
          },
        },
        {
          headerName: 'Jumlah',
          field: 'jumlah',
          resizable: true,
          // suppressSizeToFit: true,
          scopedSlots: {
            customRender: 'jumlah',
          },
        },
        {
          headerName: 'Jumlah',
          field: 'jumlah2',
          resizable: true,
          // suppressSizeToFit: true,
          scopedSlots: {
            customRender: 'jumlah2',
          },
        },
        {
          headerName: 'Keterangan',
          field: 'keterangan2',
          wrapText: true,
          autoHeight: true,
          resizable: true,
          // suppressSizeToFit: true,
          scopedSlots: {
            customRender: 'keterangan2',
          },
        },
        {
          headerName: 'Selisih',
          field: 'selisih',
          resizable: true,
          // suppressSizeToFit: true,
          scopedSlots: {
            customRender: 'selisih',
          },
        },
        {
          headerName: 'Status',
          field: 'status',
          resizable: true,
          // suppressSizeToFit: true,
          scopedSlots: {
            customRender: 'status',
          },
        },
      ],
      rowDataKeuangan: null,
      backupKeuangan: [],
      rowDataLabaRugi: null,
      backupLabaRugi: [],
      filterKodeKantor: '',
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return params.data.isPrimary !== undefined && params.data.isPrimary === 1
        },
        'font-weight-bold': (params) => {
          return params.data.isBold !== undefined
        },
      },
    }
  },
  methods: {
    moment,
    onSearch() {
      var value = this.filterKodeKantor === 'all' ? '' : this.filterKodeKantor
      this.getCompare(value)
    },
    getHtml(text, from) {
      var ntext = text
      var includelow = text.includes('kc ')
      var includeup = text.includes('KC')
      if (includelow) {
        var ntextlow = text.indexOf('kc ')
        // console.log('lowercase', text.slice(ntextlow, ntextlow + 7))
        var tlow = text.slice(ntextlow, ntextlow + 7)
        ntext = text.replace(tlow, '<a href="/#/form_' + tlow.slice(3, 5) + '" class="text-primary">' + tlow + '</a>')
      }
      if (includeup) {
        var ntextup = text.indexOf('KC')
        // console.log('uppercase', text.slice(ntextup, ntextup + 6))
        var tup = text.slice(ntextup, ntextup + 6)
        ntext = text.replace(tup, '<a href="/#/form_' + tup.slice(2, 4) + '" class="text-primary">' + tup + '</a>')
      }
      // console.log('ntext', ntext)
      // console.log('text', text)
      return ntext
    },
    onChange(e) {
      if (this.tidaksama) {
        var newFilterKeuangan = this.rowDataKeuangan.filter(
          (x) => x.status !== 'Sama',
        )
        var newFilterLabaRugi = this.rowDataLabaRugi.filter(
          (x) => x.status !== 'Sama',
        )
        this.rowDataKeuangan = newFilterKeuangan
        this.rowDataLabaRugi = newFilterLabaRugi
      } else {
        this.rowDataKeuangan = this.$g.clone(this.backupKeuangan)
        this.rowDataLabaRugi = this.$g.clone(this.backupLabaRugi)
      }
    },
    async getCompare(sandi = '') {
      var bysandi = sandi !== '' ? '?SandiKantor=' + sandi : ''
      var res = await lou.customUrlGet('master/compare' + bysandi)
      this.rowDataKeuangan = res.data.Keuangan
      this.backupKeuangan = this.$g.clone(this.rowDataKeuangan)
      this.rowDataLabaRugi = res.data.LabaRugi
      this.backupLabaRugi = this.$g.clone(this.rowDataLabaRugi)
      if (this.tidaksama) {
        this.onChange()
      }
      // this.gridApi.sizeColumnsToFit()
    },
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      // console.log('selectedRows', selectedRows)
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    formatNumber(v) {
      // console.log('lou.curency(v)', isNaN(lou.curency(v)))
      return lou.curency(v)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style></style>
